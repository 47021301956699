<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" class="mt-8 mb-8">
            <v-col cols="12" sm="8" md="6" class="text-center mb-8">
                <!-- <v-icon size="120" class="mb-4">mdi-email-check</v-icon> -->
                <v-img class="mx-auto" :width="200" src="@/assets/img/email.gif" />
                <h1 class="display-2 mb-3">Confirmation par e-mail</h1>
                <p class="subheading mb-5">
                    Merci de vous être inscrit sur Cartovia ! Nous sommes ravis de vous accueillir parmi nous.
                </p>
                <p class="subheading mb-5">
                    Pour finaliser votre inscription, veuillez confirmer votre adresse e-mail avec l'email de
                    confirmation de votre compte reçu dans votre boîte e-mail.
                </p>
                <v-btn rounded color="primary" large :to="{ name: 'Login' }">
                    <v-icon>mdi-home</v-icon> Retourner à la page de connexion
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name: 'NotFound'
};
</script>

<style>
/* Votre style ici */
</style>