import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mt-8 mb-8",attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center mb-8",attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"width":200,"src":require("@/assets/img/email.gif")}}),_c('h1',{staticClass:"display-2 mb-3"},[_vm._v("Confirmation par e-mail")]),_c('p',{staticClass:"subheading mb-5"},[_vm._v(" Merci de vous être inscrit sur Cartovia ! Nous sommes ravis de vous accueillir parmi nous. ")]),_c('p',{staticClass:"subheading mb-5"},[_vm._v(" Pour finaliser votre inscription, veuillez confirmer votre adresse e-mail avec l'email de confirmation de votre compte reçu dans votre boîte e-mail. ")]),_c(VBtn,{attrs:{"rounded":"","color":"primary","large":"","to":{ name: 'Login' }}},[_c(VIcon,[_vm._v("mdi-home")]),_vm._v(" Retourner à la page de connexion ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }